<template>
    <div class="mine_page">
        <new-header :config="headerConfig"></new-header>

        <div class="top_div">
            <div class="image_div">
                <van-image round fit="cover" class="image_avator" :src="user_avatar" />
            </div>
            <div class="text_div">
                <div class="phone_div">{{ member_mobile }}</div>
                <div class="points_div" @click="goPage(1)">
                    企业公分：{{ shares }}
                    <van-icon class="points_icon" name="arrow" />
                </div>
            </div>
        </div>
        <div class="bottom_div">
            <van-cell-group class="cell_div">
                <van-cell is-link @click="goPage(2)">
                    <template #title>
                        <img class="icon_style" src="../../assets/personalIcon/icon1.png" alt="">
                        <span class="custom-title">个人信息</span>
                    </template>
                </van-cell>
            </van-cell-group>

            <van-cell-group class="cell_div">
                <van-cell is-link @click="goPage(3)">
                    <template #title>
                        <img class="icon_style" src="../../assets/personalIcon/icon2.png" alt="">
                        <span class="custom-title">服务站点</span>
                    </template>
                </van-cell>
                <van-cell is-link @click="goPage(4)">
                    <template #title>
                        <img class="icon_style" src="../../assets/personalIcon/icon3.png" alt="">
                        <span class="custom-title">账号注销</span>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>

        <!-- 注销账号弹窗 -->
        <new-dialog :config="dialogConfig" @confirm="logoutChange"></new-dialog>

    </div>
</template>
<script>
import newDialog from '@/components/newDialog.vue';
import newHeader from "@/components/newHeader";
import { getuser_api } from "@/api/common";
import { deleteUser_api } from "@/api/perdata"
export default {
    components: {
        newDialog,
        newHeader,
    },
    data() {
        return {
            headerConfig: {
                show: true,
                iconColor: '#fff',
                backgroundColor: '#2A2828',
                titleColor: '#fff',
                title: '个人资料',
            },
            dialogConfig: {
                showDialog: false,
                text: '您确认要注销账号吗？',
                textAlign: 'center',
                cancelText: '考虑考虑',
                confirmText: '确认注销',
            },

            member_mobile: '',
            user_avatar: '',
            shares: '',
        }
    },
    created() {
        document.title = "个人资料";
        this.getUser()
    },
    methods: {
        // 获取会员信息
        async getUser() {
            let res = await getuser_api()
            if (res.code == 0) {
                this.member_mobile = res.data.member.member_mobile
                this.user_avatar = res.data.user_avatar
                this.shares = res.data.shares
            }
        },
        goPage(status) {
            if (status == 1) {
                this.$router.push("../deal/message"); // 企业工分
            }
            if (status == 2) {
                this.$router.push("../personalData/information") // 个人信息
            }
            if (status == 3) {
                this.$router.push("../personalData/serveSite")  // 服务站点
            }
            if (status == 4) {
                this.dialogConfig.showDialog = true // 账号注销
            }
        },
        // 账号注销
        async logoutChange() {
            let res = await deleteUser_api()
            if (res.code == 0) {
                console.log('注销成功');
                
            }
        },
    }
}
</script>
<style scoped lang="css">
.mine_page {
    box-sizing: border-box;
    background-color: #F9F9FB;
    min-height: 100vh;
    width: 100%;
}

.top_div {
    background-color: #2A2828;
    height: 200px;
    box-sizing: border-box;
    padding: 0 56px;
    display: flex;
    align-items: center;
}

.image_div {
    box-sizing: border-box;
    margin-right: 15px;
}

.text_div {
    height: 110px;
    box-sizing: border-box;
    align-items: flex-start;
}

.image_avator {
    width: 100px;
    height: 100px;
    border: 1px solid #fff;
}

.phone_div {
    font-size: 30px;
    color: #fff;
    margin-bottom: 18px;
}

.points_div {
    font-size: 30px;
    color: #fff;
}

.points_icon {
    font-size: 30px;
}

.bottom_div {
    padding: 30px;
    box-sizing: border-box;
    background-color: #F9F9FB;
}

.cell_div {
    margin-bottom: 30px;
}

.custom-title {
    vertical-align: middle;
}

.icon_style {
    width: 42px;
    vertical-align: middle;
    margin-right: 20px;
}

.van-dialog {
    background: url(../../assets/personalIcon/dialogBack.png);
    background-size: cover;
}

.dialog_div {
    min-height: 300px;
    text-align: center;
    padding: 50px 22px 70px 22px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.dialogTip {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 30px;
    align-items: flex-start;
}

.dialogText {
    margin-bottom: 50px;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 40px;
    text-align: left;
}

.btnArr {
    align-items: flex-end;
}

.btn1,
.btn2 {
    width: 180px;
    height: 66px;
}

.btn1 {
    margin-right: 40px;
}

.btn1>>>.van-button__text {
    color: #0091FF;
    font-size: 26px;
}

.btn2>>>.van-button__text {
    color: #D8EEFF;
    font-size: 26px;
}
</style>